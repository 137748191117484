import { createMuiTheme } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors';

require('typeface-open-sans')

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Open sans',
            'sans-serif',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#e0682c'
        },
        secondary: {
            main: '#424242',
        },
        text: {
            button: '#1976d2'
        },
        scheme1: {
            background: '#eaeaea',
            card: '#fcfcfc',
        },
        scheme2: {
            background: '#fcfcfc',
            card: '#eaeaea',
        },
        background: {
            default: '#fcfcfc',
        },
        button: {
            blue: blue[900]
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    overrides: {
        MuiTypography: {
            h2: {
                fontSize: '2rem'
            },
            h3: {
                fontSize: '1.6rem',
            },
            body2: {
                fontSize: '0.9rem'
            },
            body1: {
                fontSize: '1.05rem',
                lineHeight: '1.5rem'
            },
        },
        MuiCardHeader: {
            root: {
                padding: '1em',
                height: '100%',
                alignItems: 'flex-start'
            },
            title: {
                fontSize: '1.2rem',
                marginBottom: '0.5rem',
                fontWeight: 700,
                // background: 'red'
            },
            subheader: {
                fontSize: '1rem',
                lineHeight: '1.35rem',
                color: '#4f4f4f',
                textAlign: 'justify'
            }
        },
        MuiInputBase: {
            input: {
                backgroundColor: '#fff'
            }
        },
        MuiCardContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: '35px',
                marginLeft: 0
            }
        },
        MuiListItem: {
            gutters: {
                paddingLeft: '5px',
                paddingRight: '5px'
            },
        },
        MuiPaper: {
            // root: {
            //     // border: '1px solid #d6d6d6',
            //     // borderColor: 'd6d6d6',
            // },
            elevation1: {
                boxShadow: 'none',
            },
            rounded: {
                borderRadius: 'none'
            }
        },
        MuiButton: {
            // root: {
            //     borderRadius: '2px',

            // },
            contained: {
                boxShadow: 'none',
            },
        }
    }
});

export default theme
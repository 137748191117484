import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import { Link } from "gatsby"
// import Img from "gatsby-image";
import React, { Fragment } from 'react'
import { StaticQuery, graphql } from "gatsby"
import {
  Button,
  AppBar,
  CssBaseline,
  Drawer,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  // useScrollTrigger,
  // Typography
} from '@material-ui/core'

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Logo from '../assets/logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: theme.palette.secondary.main,
    border: 'none',
    boxShadow: 'none'
  },
  toolbar: {
    justifyContent: 'space-between'
  },
  logo: {
    padding: theme.spacing(2),
    width: '170px',
    [theme.breakpoints.down('sm')]: {
      width: '140px',
    },
  },
  menuButton: {
    color: theme.palette.background.default,
    marginRight: theme.spacing(),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  link: {
    fontSize: '1rem',
    fontWeight: 600,
    borderRadius: 0,
    borderBottom: '2px solid transparent',
    textTransform: 'uppercase',
    color: theme.palette.background.default,
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'none'
    },
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: '250px',
    backgroundColor: theme.palette.secondary.main,
  },
  mobileLink: {
    textTransform: 'uppercase',
    color: theme.palette.background.default,
  },
  active: {
    color: theme.palette.primary.main,
    borderBottom: '2px solid',
    borderColor: theme.palette.primary.main
  },
  phone: {
    textAlign: 'right',
    color: theme.palette.background.default,
    fontWeight: 600,
    fontSize: '1rem',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '0.8rem',
    },
  },
  phoneSidebar: {
    color: theme.palette.background.default,
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  box: {
    textAlign: 'right',

  },
  boxSidebar: {
      textAlign: 'center',
  },
  button: {
    padding: theme.spacing(1),
    color: theme.palette.background.default,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  }
}));

// function ElevationScroll(props) {

//   const { children } = props;
//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//   });

//   return React.cloneElement(children, {
//     elevation: trigger ? 4 : 0,
//   });
// }


function Navbar(props) {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isActive = ({ isCurrent }) => {
    return isCurrent
      ? { className: classes.active + " " + classes.link }
      : null
  }

  const navbarItems = [
    {
      title: 'Home',
      to: '/',
      active: isActive
    },
    {
      title: 'About',
      to: '/about',
      active: isActive
    },
    {
      title: 'Services',
      to: '/services',
      active: isActive
    },
    // {
    //   title: 'Team',
    //   to: '/team',
    //   active: isActive
    // },
    {
      title: 'Contact',
      to: '/contact',
      active: isActive
    },
  ]

  return (

    <React.Fragment>
      <StaticQuery
        query={graphql`
     query {
       site {
         siteMetadata {
           phoneNumber
           mailChimpUrl
             facebook
             instagram
             email
         }
       }
     }
   `}
        render={data => (
          <Fragment>
            <CssBaseline />
            {/* <ElevationScroll {...props}> */}
            <AppBar position="relative" className={classes.appbar}>
              <Toolbar className={classes.toolbar}>
                <img
                  className={classes.logo}
                  src={Logo}
                  alt={"Peak Diesel Logo"}
                />
                {navbarItems &&
                  navbarItems.map((item, i) => (
                    <Link
                      key={item.to}
                      className={classes.link}
                      to={item.to}
                      getProps={item.active}
                    >
                      {item.title}
                    </Link>
                  ))}
                <div>
                  <Button
                    className={classes.phone}
                    startIcon={<PhoneIcon />}
                    href={'tel:' + data.site.siteMetadata.phoneNumber}
                  >
                    {data.site.siteMetadata.phoneNumber}{' '}(24/7)
                  </Button>
                  <Box className={classes.box}>
                    {data.site.siteMetadata.email && (
                      <IconButton className={classes.button}
                        href={'mailto:' + data.site.siteMetadata.email}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <EmailIcon />
                      </IconButton>
                    )}

                    {data.site.siteMetadata.facebook && (
                      <IconButton className={classes.button}
                        href={data.site.siteMetadata.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FacebookIcon />
                      </IconButton>
                    )}

                    {data.site.siteMetadata.instagram && (
                      <IconButton className={classes.button}
                        href={data.site.siteMetadata.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <InstagramIcon />
                      </IconButton>
                    )}

                  </Box>
                </div>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  onClick={handleDrawerToggle}
                  color="inherit"
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            {/* </ElevationScroll> */}
            <Drawer
              variant="temporary"
              anchor="right"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {/* <img
                className={classes.logo}
                src={Logo}
                alt={"Peak Diesel Logo"}
              /> */}
              <div style={{ padding: '10px' }}>
                <List>
                  {navbarItems &&
                    navbarItems.map((item, i) => (
                      <ListItem
                        key={item.to}
                        component={Link}
                        className={classes.mobileLink}
                        to={item.to}
                      >
                        <ListItemText primary={item.title} />
                      </ListItem>
                    ))}
                  <div style={{marginTop: '30px'}}>
                    <Button
                      fullWidth
                      size="large"
                      className={classes.phoneSidebar}
                      startIcon={<PhoneIcon />}
                      href={'tel:' + data.site.siteMetadata.phoneNumber}
                    >
                      {data.site.siteMetadata.phoneNumber} (24/7)
                    </Button>
                    <Box className={classes.boxSidebar}>
                      {data.site.siteMetadata.email && (
                        <IconButton className={classes.button}
                          href={'mailto:' + data.site.siteMetadata.email}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <EmailIcon />
                        </IconButton>
                      )}

                      {data.site.siteMetadata.facebook && (
                        <IconButton className={classes.button}
                          href={data.site.siteMetadata.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FacebookIcon />
                        </IconButton>
                      )}

                      {data.site.siteMetadata.instagram && (
                        <IconButton className={classes.button}
                          href={data.site.siteMetadata.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <InstagramIcon />
                        </IconButton>
                      )}

                    </Box>
                  </div>
                </List>
              </div>
            </Drawer>
          </Fragment>
        )}
      />
    </React.Fragment>
  )
}

export default Navbar

import * as React from 'react'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import { Helmet } from "react-helmet";
import theme from 'styles/theme';

import {
  Container,
  CssBaseline,
  // Fade 
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    width: '100%',
    zoom: 0.93,
  },
}));

function Layout({ children }) {
  const classes = useStyles();
  return (
    <>
      <Helmet />
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Navbar />
        <Container
          className={classes.root}
          maxWidth={false}
        >
          {children}
        </Container>
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default Layout
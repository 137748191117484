import * as React from "react"
import { Link } from "gatsby"
import {
    Grid,
    Card,
    CardHeader,
    CardActions,
    Button,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    card: {
        // backgroundColor: theme.palette.background.default,
        padding: theme.spacing(0),
        margin: theme.spacing(0),

    },
    cardInner: {
        padding: theme.spacing(0),
        // border: '1px solid red',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
        [theme.breakpoints.up('md')]: {
            width: '95%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '75%',
        },
    },
    title: {
        margin: theme.spacing(4, 0),
        fontSize: '2.4rem',
        fontWeight: 700,
        lineHeight: '2.2rem'
    },
    action: {
        background: theme.palette.background.alternate,
        margin: theme.spacing(5, 0),
    },
    button: {
        // color: theme.palette.primary.main,
        background: theme.palette.primary.main,
        fontWeight: 600,
        borderRadius: '6px',
        // textTransform: 'none',
        fontSize: '1.3rem',
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1.5),
            fontSize: '0.9rem',
        },
        '&:hover': {
            background: theme.palette.primary.main,
        }
    },
}));

function CardSection(props) {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Card className={classes.card} style={{ background: props.scheme2 ? theme.palette.scheme2.background : theme.palette.scheme1.background }}>
            {props.title &&
                <CardHeader title={<Typography className={classes.title} component="h2" color="primary" align="center">{props.title}</Typography>} />
            }
            <div className={classes.cardInner}>
                {props.children}
                {props.buttons &&
                    <CardActions className={classes.action}>
                        <Grid
                            container
                            justify="center"
                        >
                            {props.buttons.map((button) => (
                                <Button
                                    className={classes.button}
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    component={Link}
                                    to={button.to}
                                    key={button.text}
                                    startIcon={button.icon}
                                >
                                    {button.text}
                                </Button>
                            ))
                            }
                        </Grid>
                    </CardActions>
                }
            </div>
        </Card>
    )
}

export default CardSection;